import React from 'react';
import "../style.css";
import "../docs/docs.css";
import "../docs/landing.css";

const Helper = () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;


    return (
        <div>
            <div className="layout">
                <span className="col col-main">
                    <aside className="window sidebar">
                        <div className="title-bar">
                            <button aria-label="Close" className="close"></button>
                            <h1 className="title">Fast Access</h1>
                            <button aria-label="Resize" className="resize"></button>
                        </div>
                        <div className="separator"></div>
                        <div className="window-pane">
                            <a className="btn" href="/village">
                                Back to Village
                            </a>
                            <ul className="menu-items">
                                <li>
                                    <a href="#intro">
                                        Intro
                                    </a>
                                </li>
                                <li>
                                    <a href="#village">
                                        Village
                                    </a>
                                </li>
                                <li>
                                    <a href="#vendor">
                                        Vendor
                                    </a>
                                </li>
                                <li>
                                    <a href="#underworld">
                                        Underworld Mines
                                    </a>
                                </li>
                                <li>
                                    <a href="#alchemist">
                                        Alchemist's Shop
                                    </a>
                                </li>
                                <li>
                                    <a href="#treasury">
                                        Treasury
                                    </a>
                                </li>
                                <li>
                                    <a href="#comingsoon">
                                        Coming Soon
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </span>
                <span className="col col-complementary" role="complementary">
                    <div className="standard-dialog">
                        <span className="heading center" id="intro">
                            <span style={{ fontFamily: "Warsuck", fontSize: 40 }}>
                                Introduction
                            </span>
                        </span>

                        <span>
                            <p>
                                Greetings, adventurers! In this game, your journey unfolds in the eerie yet intriguing Spooky Realm, where mysteries and challenges await around every corner. To guide you through this realm, we have compiled key information about the Village, where your interactions shape your success. Let's delve into each essential location and feature you’ll encounter on your adventure!
                            </p>
                            <p>Happy adventuring, and may the glows guide your path!</p>
                        </span>

                        <h2 id="village" className="subheading">Village: Your Central Hub</h2>
                        <section className="component">
                            <div style={{ paddingLeft: "20px" }}>
                                <span style={{ fontSize: 15 }}>
                                    Village is the heart of your journey. Here, you will interact with a range of fascinating places, each offering unique opportunities to grow, strategize, and thrive.
                                </span>
                                <br />
                            </div>
                        </section>

                        <h2 id="vendor" className="subheading">Vendor</h2>
                        <section className="component">
                            <div style={{ paddingLeft: "20px" }}>
                                <blockquote>
                                    <b>Overview:</b> The Vendor is your gateway to building a powerful empire. Purchase monsters and collectibles to kick-start your investment journey in the Underground Mines.<br /><br />
                                    <b>Available Items:</b> Monsters, eggs, potions, and other vital collectibles. These items are essential for reviving and evolving your monsters and can be acquired through gameplay or direct purchase.
                                </blockquote>
                                <br />
                            </div>
                        </section>

                        <h2 id="underworld" className="subheading">Underworld Mines</h2>
                        <section className="component">
                            <div style={{ paddingLeft: "20px" }}>
                                <div style={{ fontSize: 15 }}>The mines are a treasure trove for ambitious players. Split into two distinct levels, each offers unique rewards and interactions:</div>
                                <blockquote>
                                    <b>Level One:</b> Collect valuable gold coins, a crucial type of collectible that propels your progress.<br /><br />
                                    <b>Level Two:</b> Here, you’ll find your monsters at work. Watch closely! If a monster emits a yellow glow, it’s ready to evolve, signaling that you’ve gathered sufficient collectibles. If it’s glowing green, the monster has completed enough work, meaning it’s time to collect your gains! If you click on the monsters, you can see their vitals and gains.
                                </blockquote>
                            </div>
                        </section>

                        <h2 id="alchemist" className="subheading">Alchemist's Shop</h2>
                        <section className="component">
                            <div style={{ paddingLeft: "20px" }}>
                                <div style={{ fontSize: 15 }}>Potions, magic, and transformative powers await in the Alchemist’s Shop:</div>
                                <blockquote>
                                    <b>Collect Potions & Collectibles:</b> Choose from a variety of potions, each with unique effects tailored to your needs. Evolution potions enhance your monsters’ capabilities, boosting their work capacity and profit. Revival potions give expired monsters a second chance, letting you skip the hassle of repurchasing.<br /><br />
                                    <b>Interactive Portal:</b> To utilize potions on your monsters, simply step up to the interactive portal in the shop and watch the magic unfold!
                                </blockquote>
                            </div>
                        </section>

                        <h2 id="treasury" className="subheading">Treasury</h2>
                        <section className="component">
                            <div style={{ paddingLeft: "20px" }}>
                                <div style={{ fontSize: 15 }}>Your personal vault for monitoring and managing progress:</div>
                                <blockquote>
                                    <b>Account Details & Progress:</b> View all your profits, spendings, and individual milestones.<br /><br />
                                    <b>Deposits & Withdrawals:</b> Manage your in-game finances with ease by providing the necessary wallet addresses and following the detailed guidelines.<br /><br />
                                    <b>Monster Insights:</b> Keep track of your monsters’ accomplishments and progress right from the treasury.
                                </blockquote>
                            </div>
                        </section>

                        <h2 id="comingsoon" className="subheading">Marketplace</h2>
                        <section className="component">
                            <div style={{ paddingLeft: "20px" }}>
                                <blockquote>
                                    A highly anticipated feature! Once launched, the Marketplace will allow players to trade and sell items directly with one another, fostering a dynamic and thriving economy within the Spooky Realm.
                                </blockquote>
                            </div>
                        </section>

                    </div>
                </span>
            </div>
        </div>
    );
}

export default Helper;