import React from "react";
import "../style.css";
import "../docs/docs.css";
import "../docs/landing.css";

const ProgressBar = ({ progress }) => {
  // Ensure progress is within 0 to 100 range
  const safeProgress = Math.max(0, Math.min(progress, 100));

  return (
    <div
      style={{
        width: "300px", // Adjust width as needed
        height: "20px",
        backgroundColor: "#ffffff",
        border: "2px solid #000000", // Black border for retro effect
        display: "flex",
        padding: "2px",
        boxSizing: "border-box",
        position: "relative", // Position for text overlay
        fontFamily: "monospace", // Retro style font
        fontSize: "12px", // Adjust font size for retro look
      }}
    >
      {/* Pixelated filled bar */}
      <div
        style={{
          width: `${safeProgress}%`,
          backgroundColor: "#000000", // Black color for the filled portion
          height: "100%",
          display: "block",
          boxShadow: "inset 0 0 0 1px #ffffff", // Thin white lines for pixel effect
          boxSizing: "border-box",
          position: "absolute", // Positioning to align with text
          top: 0,
          left: 0,
        }}
      ></div>
      {/* Percentage Text */}
      <span
        style={{
          position: "absolute",
          width: "100%",
          textAlign: "center",
          color: "#ff0000", // Text color changes for visibility
          zIndex: 1, // Ensure it is above the progress bar
          fontFamily: "Chicago_12",
        }}
      >
        <b>{safeProgress.toFixed(3)}%</b>
      </span>
    </div>
  );
};

export default ProgressBar;
