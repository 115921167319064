import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Village from "./pages/Village";
import Landing from "./pages/Landing";
import Underworld from "./pages/Underworld";
import Treasury from "./pages/Treasury";
import Vendor from "./pages/Vendor";
import Alchemist from "./pages/Alchemist";
import Helper from "./pages/Helper";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/village" element={<Village />} />
        <Route path="/underworld" element={<Underworld />} />
        <Route path="/treasury" element={<Treasury />} />
        <Route path="/vendor" element={<Vendor />} />
        <Route path="/alchemist" element={<Alchemist />} />
        <Route path="/helper" element={<Helper />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
