import React from 'react';
import Phaser from "phaser";

const GameComponent = ({ config }) => {


  const game = new Phaser.Game(config);

  return (
    <div id="phaser-container">
    </div>
  );
};

export default GameComponent;
