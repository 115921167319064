import React, { useEffect, useState } from "react";
import "../style.css";
import "../docs/docs.css";
import "../docs/landing.css";
import { FaCopy } from "react-icons/fa6";
import { LiaBtc } from "react-icons/lia";
import { TbCurrencyDogecoin } from "react-icons/tb";
import { IoLogoUsd } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import ModalDefault from "../components/ModalDefault";
import ModalWithdraw from "../components/ModalWithdraw";
import ProgressBar from "../components/ProgressBar";
import axios from "axios";

const Treasury = () => {
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;

  const [walletsData, setWalletsData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("DOGE");
  const [activeTab, setActiveTab] = useState("");
  const [activeTabTRX, setActiveTabTRX] = useState("deposits");
  const [depositWallets, setDepositWallets] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [withdrawInfo, setWithdrawInfo] = useState([]);
  const [message, setMessage] = useState("");
  const [collectmessage, setCollectMessage] = useState("");
  const [monsters, setMonsters] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [messageTRX, setMessageTRX] = useState("");
  let withstatus = '';

  const getDeposits = async () => {
    try {
      const result = await axios.get(API_ENDPOINT + 'getDepositList', config);
      if (result.data) {
        const deposits = result.data.data?.deposits;
        setDeposits(deposits);
      }
    } catch (error) {
      setMessageTRX("No deposits yet.");
    }
  };

  const getWithdraws = async () => {
    try {
      const result = await axios.get(API_ENDPOINT + 'getWithdrawList', config);
      if (result.data) {
        const withdraws = result.data.data?.withdraws;
        setWithdraws(withdraws);
      }
    } catch (error) {
      setMessageTRX("Something went wrong.");
    }
  };

  const handleCopy = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        alert("Text copied to clipboard!\n" + value);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleTabClick = (contentId) => {
    setActiveTab(contentId);
  };

  const handleTabTRXClick = (tabId) => {
    setActiveTabTRX(tabId);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(selectedOption, event.target.value);
  };

  const handleSetWallet = () => {
    const body = { coin_code: selectedOption }; // Use the selected option for the coin code
    if (selectedOption) {
      axios
        .post(API_ENDPOINT + "createWallet", body, config)
        .then((result) => {
          const status = result.data?.status;
          if (status) {
            console.log(
              "Withdraw wallet set. Currency Type: " + selectedOption
            );
            getWallets(); // Refresh wallets after setting
          }
        })
        .catch((error) => {
          console.error("Error setting wallet:", error);
        });
    }
  };
  // Function to get wallets
  const getWallets = () => {
    axios
      .post(API_ENDPOINT + "getWallets", null, config)
      .then((result) => {
        const data = result.data?.data;
        if (data && data.wallets.length > 0) {
          setWalletsData(data.wallets);
          setActiveTab("content" + data.wallets[0].coin_code); // Set default active tab if wallets exist
        } else {
          console.log(
            "No withdraw wallets found. You can set them in the Treasury Ledger."
          );
          setWalletsData([]);
          setActiveTab(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching wallets:", error);
      });
  };

  const getMonsters = async () => {
    try {
      setLoading(true);
      const result = await axios.get(API_ENDPOINT + "getUserMonsters", config);
      if (result.data) {
        const monsters = result.data.data.monsters;
        setLoading(false);
        setMonsters(monsters);
      }
    } catch (error) {
      console.log("Something went wrong fething the monsters.", error);
    }
  };

  const handleCollect = async (id) => {
    const body = {
      user_monster_id: id,
    };
    try {
      const result = await axios.post(
        API_ENDPOINT + "collectMonster",
        body,
        config
      );
      if (result.data) {
        console.log(">>> monster collected successfully.");
        setCollectMessage("Monster collected successfully.");
      }
    } catch (error) {
      console.log(error);
      setCollectMessage(error.message);
    }
  };

  /*const getWithdrawById = async (id) => {
    const body = {
      "withdraw_id": id
    };
    let status = "";
    const result = await axios.post(API_ENDPOINT + 'getWithdrawById', body, config);
    if (result.data) {
      status = result.data.data?.withdraw?.status;
    }
    console.log(status);
    return status;
  };*/

  // Initial fetching of wallets
  useEffect(() => {
    getWallets();
    getDeposits();
    getWithdraws();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getMonsters();
      getWithdraws();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openWithdraw = () => {
    setWithdrawOpen(true);
  };

  const closeWithdraw = () => {
    setWithdrawOpen(false);
  };

  const handleCheckWallet = async () => {
    if (!activeTab) {
      console.error("No active tab is selected.");
      return;
    }
    const ccode = activeTab.substring(7, activeTab.length);
    if (!ccode) {
      console.error("No valid coin code found in activeTab.");
      return;
    }
    const body = { coin_code: ccode };
    try {
      const result = await axios.post(
        API_ENDPOINT + 'checkWallet',
        body,
        config
      );

      // Check if `result.data` exists and contains the expected data
      if (result.data && result.data.data) {
        const returnSet = result.data.data;

        // Handle case when response indicates an error
        if (returnSet.status === "error") {
          console.error(returnSet.message);
        } else if (returnSet.deposits) {
          setDepositWallets(returnSet.deposits);
          openModal();
        } else {
          setMessage("No deposits found in response.");
          console.error("No deposits found in response.");
        }
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error checking wallet:", error);
    }
  };

  const handleRequestWithdraw = async () => {
    const wallet = JSON.parse(sessionStorage.getItem("user")).wallet;
    const balance = JSON.parse(sessionStorage.getItem("user")).balance;
    if (wallet && balance) {
      let requestWithdraw = [wallet, balance];
      openWithdraw();
      setWithdrawInfo(requestWithdraw);
    }
  };


  useEffect(() => {
    if (walletsData.length > 0 && activeTab === "") {
      setActiveTab("content" + walletsData[0].coin_code);
    }
  }, [walletsData]);


  return (
    <div>
      <div className="layout">
        <span className="col col-main">
          <aside className="window sidebar">
            <div className="title-bar">
              <button aria-label="Close" className="close"></button>
              <h1 className="title">Fast Access</h1>
              <button aria-label="Resize" className="resize"></button>
            </div>
            <div className="separator"></div>
            <div className="window-pane">
              <a className="btn" href="/village">
                Back to Village
              </a>
              <ul className="menu-items">
                <li>
                  Personal Index
                  <ul>
                    <li>
                      <a href="#account-details">Account Details</a>
                    </li>
                    <li>
                      <a href="#activity-details">Activity Details</a>
                    </li>
                  </ul>
                </li>
                <li>
                  Generic Volume
                  <ul>
                    <li>
                      <a href="#monster-details">Monster Details</a>
                    </li>
                    <li>
                      <a href="#user-transactions">User Transactions</a>
                    </li>
                  </ul>
                </li>
                <li>
                  Support
                  <ul>
                    <li>
                      <a href="/helper">
                        For detailed information, click here.
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </aside>
        </span>
        <span className="col col-complementary" role="complementary">
          <div className="standard-dialog">
            <span className="heading center">
              <span style={{ fontFamily: "Warsuck", fontSize: 40 }}>
                Treasury Ledger
              </span>
            </span>

            <span>
              <p>
                Kept under the watchful eye of the{" "}
                <span style={{ fontFamily: "Warsuck" }}>Royal Treasurer</span>{" "}
                (a mystical entity bound to safeguard your kingdom's fortunes),
                the ledger is enchanted to be unalterable by any hand other than
                the rightful ruler’s or their appointed steward.
              </p>
              <p>
                Each entry is logged with precision, detailing the source of
                income or expense, its purpose, and the parties involved,
                ensuring transparency and accountability in the kingdom’s
                financial affairs.
              </p>
            </span>

            <h2 className="subheading">Personal Index</h2>
            <section className="component">
              <h3 className="subheading" id="account-details">
                Personal Details
              </h3>
              <div style={{ paddingLeft: "20px" }}>
                <span style={{ fontSize: 15 }}>
                  You can see your withdraw wallet address (which is the wallet
                  you used to login/register), your balance,
                  <br /> your neighbor count and the date you joined from the
                  section below.
                </span>
                <br />
                <span style={{ fontSize: 15 }}>
                  These information are important for your progress in the game.
                </span>
                <br />
                <blockquote>
                  <footer>
                    &mdash; <b>Wallet Address: </b>{" "}
                    {JSON.parse(sessionStorage.getItem("user")).wallet}&nbsp;
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        handleCopy(
                          JSON.parse(sessionStorage.getItem("user")).wallet
                        );
                      }}
                      title="click to copy wallet address"
                    >
                      <FaCopy />
                    </a>
                  </footer>
                  <footer>
                    &mdash; <b>Balance: </b>${" "}
                    {JSON.parse(sessionStorage.getItem("user")).balance}&nbsp;
                    <button className="btn" onClick={() => { handleRequestWithdraw() }}>Withdraw Balance</button>
                  </footer>
                  <footer>
                    &mdash;
                    {JSON.parse(sessionStorage.getItem("user")).ref_count <
                      1 ? (
                      "You don't have any neighbors yet. To increase your gain speed you can invite your friends and make them your neighbors."
                    ) : (
                      <>
                        <b>Neighbor Count:</b>{" "}
                        {JSON.parse(sessionStorage.getItem("user")).ref_count}{" "}
                      </>
                    )}
                    <br />
                    Here is your invite link:{" "}
                    <b>
                      {MAIN_DOMAIN}?ref=
                      {JSON.parse(sessionStorage.getItem("user")).user_id}
                    </b>{" "}
                    <a
                      href=""
                      onClick={() =>
                        handleCopy(
                          `${MAIN_DOMAIN}?ref=${JSON.parse(sessionStorage.getItem("user")).user_id
                          }`
                        )
                      }
                      title="click to copy wallet address"
                    >
                      <FaCopy />
                    </a>
                  </footer>
                  <footer>
                    &mdash; <b>Joined Date: </b>{" "}
                    {JSON.parse(sessionStorage.getItem("user")).join_date}
                  </footer>
                  {!walletsData && (
                    <span>
                      <br />
                      You have't set any <b>deposit wallets</b> yet. If you want
                      to make investments and deposit, you can generate deposit
                      wallets based on the types provided. Just pick a type and
                      our Treasurer will set one just for you.
                    </span>
                  )}
                </blockquote>
                {walletsData && (
                  <span>
                    Our Treasurer has set these deposit wallets for you,
                    exclusively. No one but the Treasurer can see them. Once you
                    start depositing currency, your balance will be visible in
                    here.
                  </span>
                )}
                <div style={{ paddingLeft: "20px" }} className="container">
                  <div className="box2">
                    {(!walletsData || walletsData.length < 4) && (
                      <div className="form-container">
                        <select
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option value="BTC">
                            BTC <LiaBtc />
                          </option>
                          <option value="DOGE">
                            DOGE <TbCurrencyDogecoin />
                          </option>
                          <option value="USDT">
                            USDT <IoLogoUsd />
                          </option>
                        </select>
                        <button
                          id="setWallet"
                          className="btn"
                          onClick={handleSetWallet}
                        >
                          Set
                        </button>
                      </div>
                    )}
                    <div className="panel">
                      <ul role="menu-bar">
                        {walletsData && walletsData.length > 0 ? (
                          walletsData.map((item, index) => (
                            <li
                              key={index}
                              role="menu-item"
                              tabIndex={index}
                              className={`menu-item ${activeTab === "content" + item.coin_code
                                ? "active"
                                : ""
                                }`}
                              onClick={() =>
                                handleTabClick("content" + item.coin_code)
                              }
                            >
                              {item.coin_code}
                            </li>
                          ))
                        ) : (
                          <li>No wallets available.</li>
                        )}
                      </ul>
                      {walletsData &&
                        walletsData.length &&
                        walletsData.map(
                          (item, index) =>
                            activeTab === "content" + item.coin_code && (
                              <div
                                id={"content" + item.coin_code}
                                key={index}
                                className="tab-content"
                              >
                                <p
                                  style={{
                                    fontFamily: "Chicago_12",
                                    fontSize: 18,
                                  }}
                                >
                                  &nbsp;{item.wallet_address}&nbsp;
                                  <button
                                    onClick={() =>
                                      handleCopy(item.wallet_address)
                                    }
                                    title="click to copy wallet address"
                                    className="btn"
                                  >
                                    <FaCopy />
                                  </button>
                                </p>
                                <p style={{ fontFamily: "Chicago_12" }}>
                                  Created:&nbsp;{item.created_at}
                                </p>
                                <hr />
                                <button
                                  className="btn"
                                  onClick={handleCheckWallet}
                                >
                                  Check Wallet Transactions
                                </button>
                                <br />
                                <span
                                  style={{
                                    fontFamily: "Chicago_12",
                                    fontSize: 12,
                                  }}
                                >
                                  {message}
                                </span>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="component">
              <h3 className="subheading" id="activity-details">
                Activity Details
              </h3>
              <div style={{ paddingLeft: "20px" }}>
                <span style={{ fontSize: 15 }}>
                  With the live-stock and common props such as trees and coins,
                  you can increase the amount of your valuables and start making{" "}
                  <br />
                  some progress in the village. These resources help players
                  level up and make significant progress.
                  <br />
                  The marketplace allows players to trade livestock, mined
                  treasures, and crafted goods with others,
                  <br />
                  fostering a thriving economy and accelerating overall progress
                  in the game.
                </span>
                <br />
                <blockquote className="scrollable-blockquote">
                  <div className="square-container">
                    <div className="square" style={{ display: "contents" }}>
                      <img
                        src="assets/Resources/Resources/M_Idle.png"
                        style={{ height: "96px", width: "96px" }}
                      />
                      <ul>
                        <li>
                          ✦ You have{" "}
                          {JSON.parse(sessionStorage.getItem("user")).meat} meat
                          collected <br />
                          in your inventory.
                        </li>
                        <li>
                          ✦ You can get more by slaying <br /> some sheep in the{" "}
                          <a href="/village">Village</a>.
                        </li>
                        <li>
                          ✦ You can feed your monsters <br />
                          with the meat you collected.
                        </li>
                      </ul>
                    </div>
                    <div className="square" style={{ display: "contents" }}>
                      <img
                        src="assets/goldcoin.png"
                        style={{
                          height: "96px",
                          width: "96px",
                          imageRendering: "pixelated",
                        }}
                      />
                      <ul>
                        <li>
                          ✦ You have{" "}
                          {JSON.parse(sessionStorage.getItem("user")).coin}{" "}
                          coins collected <br />
                          in your inventory.
                        </li>
                        <li>
                          ✦ You can get more by picking
                          <br /> them in the{" "}
                          <a href="/underworld">Underworld</a>.
                        </li>
                        <li>
                          ✦ If you don't pick them,
                          <br /> they will expire.
                        </li>
                      </ul>
                    </div>
                    <div className="square" style={{ display: "contents" }}>
                      <img
                        src="assets/potions.png"
                        style={{
                          height: "96px",
                          width: "96px",
                          imageRendering: "pixelated",
                        }}
                      />
                      <ul>
                        <li>
                          ✦ You have{" "}
                          {JSON.parse(sessionStorage.getItem("user")).potion}{" "}
                          potions collected <br />
                          in your inventory.
                        </li>
                        <li>
                          ✦ You can get more by picking
                          <br /> them in the <a href="/alchemist">Alchemist</a>.
                        </li>
                        <li>
                          ✦ You can use them if you want to
                          <br /> evolve your monsters.
                        </li>
                      </ul>
                    </div>
                    <div className="square" style={{ display: "contents" }}>
                      <img
                        src="assets/Resources/Resources/W_Idle.png"
                        style={{ height: "96px", width: "96px" }}
                      />
                      <ul>
                        <li>
                          ✦ You have{" "}
                          {JSON.parse(sessionStorage.getItem("user")).wood} wood
                          collected <br />
                          in your inventory.
                        </li>
                        <li>
                          ✦ You can get more by picking
                          <br /> them in the <a href="/village">Village</a>.
                        </li>
                        <li>
                          ✦ You can use them if you want to
                          <br /> evolve your monsters.
                        </li>
                      </ul>
                    </div>
                  </div>
                </blockquote>
              </div>
            </section>

            <h2 className="subheading" id="monster-details">Gain Information</h2>
            <span>
              This is where you can find information regarding your gains and
              investments.
              <code>
                {loading && " Fetching monster information..."}
              </code>
            </span>

            <section className="component">
              <div className="monster-grid">
                {monsters.map((monster, index) => (
                  <div className="window scale-down" style={{ width: "26rem" }}>
                    <div className="title-bar">
                      <button aria-label="Close" className="close"></button>
                      <h1 className="title">{monster.monster_name}</h1>
                      <button
                        aria-label="Resize"
                        disabled
                        className="hidden"
                      ></button>
                    </div>
                    <div className="separator">
                    </div>
                    <div className="modeless-dialog">
                      <section
                        className="field-row"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <div className="square-alt">
                          <img
                            src={`${MAIN_DOMAIN}images${monster.gif_link}`}
                            alt="Item"
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >

                          <span className="info-alt" style={{ color: 'red' }}><b>Level {monster.level}</b></span>
                          <span className="info-alt">
                            <b>Expire Date: </b>
                            {monster.expire_date}
                          </span>
                          <span className="info-alt">
                            <b>Collect Date: </b>
                            {monster.collect_time}
                          </span>
                          <span className="info-alt">
                            <b>HP: </b>
                            {monster.hp} MH/s
                          </span>
                          <span className="info-alt">
                            <b>Gain: </b>$ {monster.gain}
                          </span>
                          <span className="info-alt">
                            <b>Collect Percentage:</b>{" "}
                            <ProgressBar
                              progress={monster.collect_percentage}
                            />
                          </span>
                          <span className="info-alt">
                            <b>Evolve Requirements: </b>
                            {monster.can_evolve ? (
                              <FaCircleCheck
                                style={{ fontSize: "8px", color: "green" }}
                              />
                            ) : (
                              `🪵 ${monster.wood_required} • 🥩 ${monster.meat_required} • 🧙 ${monster.potion_required} • 💰 ${monster.coin_required}`
                            )}
                          </span>
                        </div>
                      </section>
                      <section
                        className="field-row"
                        style={{ justifyContent: "flex-end" }}
                      >
                        {monster.can_collect && (
                          <>
                            <button
                              className="btn"
                              style={{ width: "95px" }}
                              onClick={() => {
                                handleCollect(monster.id);
                              }}
                            >
                              Collect
                            </button>
                            <br />
                            <span style={{ fontFamily: 'Chicago_12', fontSize: '15px', color: 'red' }}>{collectmessage}</span>
                          </>
                        )}
                      </section>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section className="component">
              <h3 className="subheading" id="user-transactions">
                User Transactions
              </h3>
              <div style={{ paddingLeft: "20px" }}>
                <blockquote>
                  In this section, you can see your transaction details.
                  In the deposits tab, you can see the details of your investments.
                  In the withdraws tab, you can see your withdraw requests.
                </blockquote>
                <>
                  <div className="panel">
                    <ul role="menu-bar">
                      <li role="menu-item" className={`menu-item ${activeTabTRX === "deposits" ? "active" : ""}`} onClick={() => handleTabTRXClick("deposits")}>Deposits</li>
                      <li role="menu-item" className={`menu-item ${activeTabTRX === "withdraws" ? "active" : ""}`} onClick={() => handleTabTRXClick("withdraws")}>Withdraws</li>
                    </ul>
                    <div id="deposits" className={`tab-content ${activeTabTRX === "deposits" ? "active" : "hidden"}`}>
                      {deposits && (
                        <table style={{ fontFamily: 'Chicago_12', fontSize: '15px', border: '1px solid black', width: '100%' }}>
                          <thead>
                            <tr>
                              <th style={{ border: '1px solid black' }}>TX ID</th>
                              <th style={{ border: '1px solid black' }}>TX Link</th>
                              <th style={{ border: '1px solid black' }}>Coin Code</th>
                              <th style={{ border: '1px solid black' }}>Amount</th>
                              <th style={{ border: '1px solid black' }}>USDT Amount</th>
                              <th style={{ border: '1px solid black' }}>Wallet Address</th>
                              <th style={{ border: '1px solid black' }}>Exchange Rate</th>
                              <th style={{ border: '1px solid black' }}>Deposit Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {deposits.map((dep, i) => (
                              <tr key={i} style={{ border: '1px solid black' }}>
                                <td style={{ border: '1px solid black' }}>{dep.txid}</td>
                                <td style={{ border: '1px solid black' }}><a href={dep.txid_link} rel="noreferrer" target='_blank'>TX Link</a></td>
                                <td style={{ border: '1px solid black' }}>{dep.coin_code}</td>
                                <td style={{ border: '1px solid black' }}>{parseFloat(dep.amount).toFixed(2)}</td>
                                <td style={{ border: '1px solid black' }}>{parseFloat(dep.usdt_value).toFixed(4)}</td>
                                <td style={{ border: '1px solid black' }}>{dep.wallet_address}</td>
                                <td style={{ border: '1px solid black' }}>{dep.usdt_value_per_unit}</td>
                                <td style={{ border: '1px solid black' }}>{dep.deposit_date}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )
                      }
                    </div>
                    <div id="withdraws" className={`tab-content ${activeTabTRX === "withdraws" ? "active" : "hidden"}`}>
                      {withdraws && (
                        <table style={{ fontFamily: 'Chicago_12', fontSize: '15px', border: '1px solid black', width: '100%' }}>
                          <thead>
                            <tr>
                              <th style={{ border: '1px solid black' }}>Status</th>
                              <th style={{ border: '1px solid black' }}>TX ID</th>
                              <th style={{ border: '1px solid black' }}>TX Link</th>
                              <th style={{ border: '1px solid black' }}>Coin Code</th>
                              <th style={{ border: '1px solid black' }}>Amount</th>
                              <th style={{ border: '1px solid black' }}>USDT Amount</th>
                              <th style={{ border: '1px solid black' }}>Wallet Address</th>
                              <th style={{ border: '1px solid black' }}>Deposit Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withdraws.map((wit, i) => (
                              <tr key={i} style={{ border: '1px solid black' }}>
                                {wit.status === 'pending' &&
                                  <td style={{ border: '1px solid black', color: 'orange' }}>{wit.status}</td>
                                }
                                {wit.status === 'done' && <td style={{ border: '1px solid black', color: 'green' }}>{wit.status}</td>}
                                <td style={{ border: '1px solid black' }}>{wit.txid}</td>
                                <td style={{ border: '1px solid black' }}><a href={wit.txid_link} rel="noreferrer" target='_blank'>TX Link</a></td>
                                <td style={{ border: '1px solid black' }}>{wit.coin_code}</td>
                                <td style={{ border: '1px solid black' }}>{parseFloat(wit.amount).toFixed(2)}</td>
                                <td style={{ border: '1px solid black' }}>{parseFloat(wit.usdt_value).toFixed(4)}</td>
                                <td style={{ border: '1px solid black' }}>{wit.wallet_address}</td>
                                <td style={{ border: '1px solid black' }}>{wit.request_date}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )
                      }
                    </div>
                  </div>
                </>
              </div>
            </section>
          </div>
        </span>
      </div>
      {modalOpen && (
        <ModalDefault
          onClose={closeModal}
          props={["Deposit Transactions", depositWallets]}
        />
      )}
      {withdrawOpen && (
        <ModalWithdraw
          onClose={closeWithdraw}
          props={["Request Withdraw", withdrawInfo]}
        />
      )}
    </div>
  );
};

export default Treasury;
