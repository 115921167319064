import React, { useState } from 'react';

const ModalDefault = ({ onClose, props }) => {

  
  return (
    <>
    {props && 
    <div className="modal-overlay">
        <div className="modal" style={{width:"30rem"}}>
        <div className="title-bar">
          <button aria-label="Close" className="close" onClick={onClose}></button>
          <h1 className="title">{props[0]}</h1>
          <button aria-label="Resize" disabled className="hidden"></button>
        </div>
        <div className="separator"></div>
  
        <div className="modeless-dialog">
          <section className="field-row" style={{justifyContent: "flex-start"}}>
            {props[1].length < 1 && "There are no deposits yet."}
            {props[1].length > 0 && 
            <>
            <table>
            <thead>
              <tr>
                <th>Coin Code</th>
                <th>Coin Amount</th>
                <th>USDT Value</th>
                <th>USDT Total</th>
                <th>TRX ID</th>
              </tr>
            </thead>
            <tbody>
              {props[1].map((index, item)=>{
                <tr key={index}>
                  <td>{item.coin_code}</td>
                  <td>{item.coin_amount}</td>
                  <td>{item.usdt_value}</td>
                  <td>{item.usdt_total_value}</td>
                  <td>{item.transaction_id}</td>
                </tr>
              })}
            </tbody>
            </table>
            </>
            }
          </section>
          <section className="field-row" style={{justifyContent: "flex-end"}}>
            <button className="btn" onClick={onClose}>Close</button>
          </section>
        </div>
        </div>
    </div>
    }
    </>
  );
};

export default ModalDefault;
