import React, { useState } from 'react';

const Modal = ({ onClose, props }) => {

    return (
    <>
    {props && 
    <div className="modal-overlay">
        <div className='modal'>
            <div className="title-bar">
                <h1 className="title">{props[0]}</h1>
                <button aria-label="Close" className="close" onClick={onClose}></button>
            </div>
            <div className="separator"></div>
                <div className="window-pane" style={{fontFamily: 'Chicago_12', fontSize: '18px'}}>
                {props[1]} <br/>
                </div>
            </div>
    </div>
    }
    </>
    );
}

export default Modal;
