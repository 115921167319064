import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "./Loading";

const ModalLogin = ({ onClose, props }) => {
  const navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    let info = window.location.href + "";
    let refid = "";
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    if (info.split("?")) {
      refid = info.split("=")[1];
    }
    let formData = { wallet: walletAddress, inviter_id: refid };
    try {
      const response = await axios.post(API_ENDPOINT + "loginReg", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const token = response?.data?.data?.token;
      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        try {
          const r = axios
            .post(API_ENDPOINT + "getUser", null, config)
            .then((result) => {
              const status = result?.data.status;
              if (status === "success") {
                const userdata = result.data.data.user;
                const propsdata = result.data.data.daily_items;

                localStorage.clear();
                sessionStorage.clear();
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("user", JSON.stringify(userdata));
                sessionStorage.setItem("props", JSON.stringify(propsdata));

                console.log(">>> user info is retrieved. login is completed.");

                setTimeout(() => {
                  navigate("/village");
                }, 250);
              }
            });
        } catch (error) {
          console.error("Error:", error.message);
          //window.location.href = "/";
        }
        onClose();
      }
      // Handle response as needed
    } catch (error) {
      console.error("Error:", error.message);
      // Handle error as needed
    }
  };
  const updateInputValue = (evt) => {
    const val = evt.target.value;
    setWalletAddress(val);
  };

  return (
    <>
      {loading && <Loading />}
      {props && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="title-bar">
              <button
                aria-label="Close"
                className="close"
                onClick={onClose}
              ></button>
              <h1 className="title">{props[0]}</h1>
              <button aria-label="Resize" disabled className="hidden"></button>
            </div>
            <div className="separator"></div>
            <div className="modeless-dialog">
              <section
                className="field-row"
                style={{ justifyContent: "flex-start" }}
              >
                <label htmlFor="text_find" className="modeless-text">
                  {props[1]}:
                </label>
                <input
                  id="text_find"
                  type="text"
                  style={{ width: "100%" }}
                  placeholder=""
                  onChange={(evt) => updateInputValue(evt)}
                />
              </section>
              <section
                className="field-row"
                style={{ justifyContent: "flex-end" }}
              >
                <button className="btn" onClick={onClose}>
                  Cancel
                </button>
                <button
                  className="btn"
                  style={{ width: "95px" }}
                  onClick={handleClick}
                >
                  {props[2]}
                </button>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalLogin;
