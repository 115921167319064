import React, { useEffect, useState } from "react";
import "../style.css";
import "../docs/docs.css";
import "../docs/landing.css";
import { FaCopy } from "react-icons/fa6";
import { GiConfirmed } from "react-icons/gi";
import axios from "axios";
import Modal from "../components/Modal";

const Vendor = () => {
  const [items, setItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem("token");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const getItemList = () => {
    const itemList = axios
      .post(API_ENDPOINT + "getShopList", null, config)
      .then((result) => {
        if (result.data?.data?.shopItems) {
          const items = result.data?.data?.shopItems;
          setLoading(false);
          setItems(items);
        } else {
          console.log(
            ">>> something went wrong. there is nothing in the shop!"
          );
        }
      });
  };
  getItemList();

  const copyToClipboard = (text) => {
    if (navigator.clipboard && window.isSecureContext) {
      // Use the Clipboard API
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback method for older browsers
      let textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.position = "fixed";
      textArea.style.top = "-9999px";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          alert("Copied to clipboard!");
        } else {
          console.error("Failed to copy");
        }
      } catch (err) {
        console.error("Fallback: Unable to copy", err);
      }

      document.body.removeChild(textArea);
    }
  };
  const closeModal = () => {
    setModalShow(false);
  };

  const handleBuyItem = (itemid) => {
    const purchaseInfo = {
      item_id: itemid,
    };
    axios
      .post(API_ENDPOINT + "purchaseShop", purchaseInfo, config)
      .then((result) => {
        if (result.data?.data) {
          setModalShow(true);
          setMessage(["Bought new item!", "Your transaction was successful."]);
          console.log(">>> new item bought.");
        } else {
          setModalShow(true);
          setMessage(["Oh no!", "Something went wrong " + " 😰"]);
        }
      })
      .catch((error) => {
        // Handle the error response
        setModalShow(true);
        setMessage(["Oh no!", error + " 😰"]);
      });
  };

  const handleClick = (id) => {
    console.log(id);
    handleBuyItem(id);
    totalItemsChecker();
  };

  const totalItemsChecker = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    axios.get(API_ENDPOINT + "getUser", config).then((result) => {
      const checkItems = result?.data?.data.daily_items;
      const userInfo = result?.data?.data.user;

      if (checkItems) {
        sessionStorage.removeItem("props");
        sessionStorage.setItem("props", JSON.stringify(checkItems));
      }

      if (userInfo) {
        sessionStorage.removeItem("user");
        sessionStorage.setItem("user", JSON.stringify(userInfo));
      }
    });
  };

  return (
    <div>
      <div className="layout">
        <span className="col col-main">
          <aside className="window sidebar">
            <div className="title-bar">
              <button aria-label="Close" className="close"></button>
              <h1 className="title">Useful Info</h1>
              <button aria-label="Resize" className="resize"></button>
            </div>
            <div className="separator"></div>
            <div className="window-pane">
              <a className="btn" href="/village">
                Back to Village
              </a>
              <ul className="menu-items">
                <li>
                  Deposit Account Details
                  <ul>
                    <li>
                      <b>Wallet: </b>
                      {JSON.parse(
                        sessionStorage.getItem("user")
                      ).wallet.substring(0, 3) +
                        "..." +
                        JSON.parse(sessionStorage.getItem("user")).wallet.slice(
                          -2
                        )}
                      &nbsp;{" "}
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          copyToClipboard(
                            JSON.parse(sessionStorage.getItem("user")).wallet
                          );
                        }}
                        title="click to copy wallet address"
                      >
                        <FaCopy />
                      </a>
                    </li>
                    <li>
                      <b>Balance: </b>${" "}
                      {JSON.parse(sessionStorage.getItem("user")).balance}
                    </li>
                    <li>
                      <b>Validated: </b>
                      <span
                        style={{
                          color: "#74ff33",
                          fontSize: "18px",
                          textAlign: "center",
                        }}
                      >
                        <GiConfirmed />
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  Support
                  <ul>
                    <li>
                      <a href="/helper">
                        For detailed information, click here.
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </aside>
        </span>
        <span className="col col-complementary" role="complementary">
          <div className="standard-dialog">
            <span className="heading center">
              <span style={{ fontFamily: "Warsuck", fontSize: 40 }}>
                Mystic Menagerie Vendor
              </span>
            </span>

            <h2 className="subheading"></h2>
            <span>
              <p>
                At the{" "}
                <span style={{ fontFamily: "Warsuck" }}>
                  Mystic Menagerie Vendor
                </span>
                , fantastical creatures of all kinds await adventurers and
                collectors alike. Each creature comes with its own unique set of
                characteristics, stamina levels, and dietary requirements, all
                of which impact their price.
              </p>
              <p>Here’s a look at some of the offerings:</p>
              <p>
                <code>{loading && " Fetching shop items..."}</code>
              </p>
            </span>

            <hr></hr>

            <div className="monster-grid">
              {items &&
                items.map((item, index) => (
                  <div
                    className="alert-box outer-border scale-down"
                    style={{ width: "27rem" }}
                    key={index}
                  >
                    <div className="inner-border">
                      <div className="alert-contents">
                        <section className="field-row">
                          <div className="square">
                            <img
                              src={`${MAIN_DOMAIN}images${item.image}`}
                              alt="Item"
                            />
                          </div>
                          <div className="infox">
                            <span className="name">{item.name}</span>
                            <p className="alert-text m-0">{item.description}</p>
                            <p>
                              <ul role="menu-bar">
                                <li
                                  role="menu-item"
                                  tabIndex="0"
                                  aria-haspopup="true"
                                  style={{
                                    backgroundColor: "#000",
                                    color: "#fff",
                                  }}
                                >
                                  {(() => {
                                    const validItemTypes = [
                                      "wood",
                                      "coin",
                                      "meat",
                                      "potion",
                                    ];

                                    // This part returns the "collectable " string if the item is collectable
                                    if (
                                      validItemTypes.includes(item.item_type)
                                    ) {
                                      return "collectable ";
                                    } else {
                                      return item.item_type + " ";
                                    }
                                  })()}

                                  {(() => {
                                    const validItemTypes = [
                                      "wood",
                                      "coin",
                                      "meat",
                                      "potion",
                                    ];

                                    if (item.item_type === "monster") {
                                      return "👾";
                                    } else if (
                                      validItemTypes.includes(item.item_type)
                                    ) {
                                      return "🧺";
                                    } else if (item.item_type === "egg") {
                                      return "🥚";
                                    }
                                  })()}
                                </li>
                              </ul>
                            </p>
                          </div>
                        </section>
                        {/* Action Buttons */}
                        <section
                          className="field-row"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            className="btn"
                            onClick={() => handleClick(item.item_id)}
                          >
                            $ {item.price_usd}
                          </button>
                        </section>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </span>
      </div>

      {modalShow && (
        <Modal onClose={closeModal} props={[message[0], message[1]]} />
      )}
    </div>
  );
};

export default Vendor;
