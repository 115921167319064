import React, { useState } from "react";
import axios from "axios";

const ModalWithdraw = ({ onClose, props }) => {
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [message, setMessage] = useState("");
  const updateInputValue = (evt) => {
    const val = evt.target.value;
    setWithdrawAmount(val);
  };
  const handleWithdraw = async () => {
    const amount = withdrawAmount;
    const token = sessionStorage.getItem("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = { amount: amount };
    const result = await axios.post(API_ENDPOINT + "setWithdraw", body, config);
    if (result.data.status === "success") {
      setMessage(result.data.data + ".");
    } else {
      setMessage(result.data.data + ".");
    }
  };
  return (
    <>
      {props && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="title-bar">
              <h1 className="title">{props[0]}</h1>
              <button
                aria-label="Close"
                className="close"
                onClick={onClose}
              ></button>
            </div>
            <div className="separator"></div>
            <div className="modeless-dialog">
              <p>
                Please take note your wallet address, the requested amount will
                be transferred to it.
              </p>
              <p>
                ❗ Minimum withdraw amount is $50, any request that is under
                mininmum withdraw amount will not be carried out.
              </p>
              <p>
                ❗ If you already have a pending withdraw request, you cannot
                make another withdraw request.
              </p>
              <p>
                <label className="modeless-text">
                  Withdraw Wallet Address: {props[1][0]}
                </label>
              </p>
              <p>
                <label className="modeless-text">
                  Balance Amount: $ {props[1][1]}
                </label>
              </p>
              <section
                className="field-row"
                style={{ justifyContent: "flex-start" }}
              >
                <label htmlFor="text_find" className="modeless-text">
                  Withdraw Amount:
                </label>
                <input
                  id="text_find"
                  type="text"
                  style={{ width: "100%" }}
                  placeholder=""
                  onChange={(evt) => updateInputValue(evt)}
                />
              </section>
              <section
                className="field-row"
                style={{ justifyContent: "flex-end" }}
              >
                <button className="btn" onClick={onClose}>
                  Cancel
                </button>
                <button
                  className="btn"
                  style={{ width: "225px" }}
                  onClick={handleWithdraw}
                >
                  Request Withdraw
                </button>
              </section>
              <p>{message}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalWithdraw;
