import React, { useEffect, useState } from "react";
import "../index";
import { useNavigate } from "react-router-dom";
import "../style.css";
import "../docs/docs.css";
import "../docs/landing.css";
import ModalLogin from "../components/ModalLogin";
const LandingPage = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    openModal();
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const images = [
    "landing/goldsack.png",
    "landing/happysheep.gif",
    "landing/meat.png",
  ];
  useEffect(() => {
    const createFallingImage = () => {
      const header = document.querySelector(".header");
      const img = document.createElement("img");
      const randomImage = images[Math.floor(Math.random() * images.length)];

      img.src = randomImage;
      img.className = "falling-image";
      img.style.left = Math.random() * 100 + "%"; // Random horizontal position

      if (header) header.appendChild(img);

      // Remove the image after animation ends
      img.addEventListener("animationend", () => {
        img.remove();
      });
    };

    // Create an interval to generate falling images every second
    const interval = setInterval(createFallingImage, 1000);

    // Clean up interval when component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="landing-page">
      <header className="header">
        <div className="navbar">
          <div className="logo">The Realm</div>
          <nav>
            <a className="title" href="#about">
              About
            </a>
            <a href="#features">Features</a>
            <button className="btn" onClick={handleClick}>
              Play Now
            </button>
          </nav>
        </div>
        <div className="hero">
          <h1>Enter a World Where Quests Meet Crypto</h1>
          <p>
            Dive into a realm where mythical creatures, enchanted lands, and
            epic quests merge with the power of decentralized finance.
          </p>
          <button className="btn" onClick={handleClick}>
            Play for Free
          </button>
        </div>
      </header>

      <section id="about" className="section">
        <h2>About Us</h2>
        <p>
          Dive into a realm where mythical creatures, enchanted lands, and epic
          quests merge with the power of decentralized finance. Whomever you
          are, every quest you undertake, every treasure you claim contributes
          to your journey towards wealth and prestige.
        </p>
      </section>

      <section id="features" className="section features">
        <h2>Features</h2>
        <div className="feature-item">
          <h3>Digital Currency</h3>
          <p>
            <b>Earn, Trade, and Rule:</b>
            Our players are not just adventurers; they're investors and
            strategists. Complete quests to earn $MYST, our native currency,
            which can be traded, staked, or used to unlock exclusive in-game
            assets. From upgrading your hero’s gear to purchasing rare mounts
            and magical artifacts, our marketplace is your gateway to endless
            possibilities.
          </p>
        </div>
        <div className="feature-item">
          <h3>Marketplace</h3>
          <p>
            Welcome to the Marketplace, the bustling financial heart of our
            realm. Here, adventurers and strategists alike gather to trade their
            hard-earned treasures, powerful artifacts, and rare props, all in
            exchange for $MYST, our native cryptocurrency.
          </p>
        </div>
        <p>
          In this dynamic finance zone, you have the freedom to buy, sell, or
          auction items obtained from your quests or crafted using rare
          resources. Whether it's enchanted armor, powerful spellbooks,
          legendary mounts, or mystical ingredients, every asset in the
          marketplace holds real value and can enhance your gameplay or boost
          your wealth.
        </p>
        <p>
          <b>Trade Freely:</b> The marketplace operates on a decentralized
          system, ensuring a fair and open economy. Players can set their own
          prices, negotiate trades, and discover valuable assets without
          intermediaries. With $MYST as the primary currency, every transaction
          becomes an opportunity to grow your fortune or acquire rare and
          powerful items.
        </p>
        <p>
          <b>Invest and Expand:</b> Beyond trading, the marketplace also allows
          you to stake your $MYST tokens for special rewards, gain access to
          exclusive content, or participate in time-limited events where rare
          items become available. It's not just about trading; it’s about
          building your legacy and expanding your influence in the realm.
        </p>
      </section>

      <footer className="footer">
        <p>© 2024 The Realm. All rights reserved.</p>
      </footer>
      {modalOpen && (
        <ModalLogin
          onClose={closeModal}
          props={["Enter Wallet Address", "Address", "Login"]}
        />
      )}
    </div>
  );
};

export default LandingPage;
