import Phaser from "phaser";
import "../docs/fonts.css";
import axios from "axios";

class UIScene extends Phaser.Scene {
  constructor() {
    super({ key: "ui-scene", active: true });
    this.state = {
      plocation: window.location.href,
      popup: null,
      infoGroup: null,
    };
    this.MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
    this.API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    this.totalItemsChecker();
  }

  totalItemsChecker() {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    axios.get(this.API_ENDPOINT + "getUser", config).then((result) => {
      const checkItems = result?.data?.data.daily_items;
      const userInfo = result?.data?.data.user;

      if (checkItems) {
        sessionStorage.removeItem("props");
        sessionStorage.setItem("props", JSON.stringify(checkItems));
      }

      if (userInfo) {
        sessionStorage.removeItem("user");
        sessionStorage.setItem("user", JSON.stringify(userInfo));
        this.totalCoinCount = parseInt(userInfo.coin);
        this.totalMeatCount = parseInt(userInfo.meat);
        this.totalPotionCount = parseInt(userInfo.potion);
        this.totalWoodCount = parseInt(userInfo.wood);
      }
    });
  }

  preload() {
    // Wait for the font to load before proceeding
    this.load.once("complete", () => {
      document.fonts.load("16px Chicago_12").then(() => { });
    });
    this.load.image("infobutton", "assets/UI/yellowButton.png");
    this.load.image("defaultbutton", "assets/UI/disabledButton.png");
    this.load.image("cog", "assets/UI/Icon_Settings.png");
    this.load.image("balanceinfo", "assets/UI/yellowRibbonLeft.png");
    this.load.image("info", "assets/UI/Icon_Info.png");
    this.load.image("modalbackground", "assets/UI/panel.png");
    this.load.image("arrow", "assets/UI/BackArrow.png");
    this.load.image("backbutton", "assets/UI/redButton.png");
    this.load.image("x", "assets/UI/Icon_X.png");
    this.load.image("check", "assets/UI/Icon_Check.png");
    this.load.image("cart", "assets/UI/Icon_Cart.png");
    this.load.image("globe", "assets/UI/Icon_Globe.png");
    this.load.image("status", "assets/UI/status.png");
    this.load.image("beef", "assets/Resources/Resources/M_Idle.png");
    this.load.image("log", "assets/Resources/Resources/W_Idle.png");
    this.load.image("potion", "assets/potions.png");
    this.load.image("goldcoin", "assets/alchemist/sprites/Props/Coins-2.png");
    this.load.image("check", "assets/UI/Icon_Check.png");
    this.load.image("dot", "assets/dot.png");

    this.totalItemsChecker();
  }

  create() {
    try {
      const pname = this.state.plocation.split("/")[3];
      const infobutton = this.add.image(1200, 45, "infobutton");
      this.add.text(1165, 30, pname.charAt(0).toUpperCase() + pname.slice(1), {
        font: "18px Warsuck",
        fill: "#000000",
      });

      this.add.image(45, 540, "status").setScale(0.4, 0.4);
      this.add.image(42, 525, "beef");
      this.totalMeatText = this.add.text(58, 545, this.totalMeatCount, {
        font: "16px Chicago_12",
        fill: "#ffee00",
        backgroundColor: "#000000",
      });

      this.add.image(45, 460, "status").setScale(0.4, 0.4);
      this.add.image(45, 440, "log");
      this.totalWoodText = this.add.text(58, 465, this.totalWoodCount, {
        font: "16px Chicago_12",
        fill: "#ffee00",
        backgroundColor: "#000000",
      });

      this.add.image(45, 380, "status").setScale(0.4, 0.4);
      this.add.image(45, 380, "goldcoin").setScale(2, 2);
      this.totalCoinText = this.add.text(58, 385, this.totalCoinCount, {
        font: "16px Chicago_12",
        fill: "#ffee00",
        backgroundColor: "#000000",
      });

      this.add.image(45, 300, "status").setScale(0.4, 0.4);
      this.add.image(45, 300, "potion").setScale(2, 2);
      this.totalPotionText = this.add.text(58, 305, this.totalPotionCount, {
        font: "16px Chicago_12",
        fill: "#ffee00",
        backgroundColor: "#000000",
      });

      const settingsButton = this.add.image(1350, 45, "defaultbutton");
      this.add.image(1350, 42, "cog").setScale(0.12);
      settingsButton.setInteractive({ useHandCursor: true });
      settingsButton.on("pointerdown", () => {
        console.log(">>> settings ui should open.");
        this.openWindow();
      });

      const shopButton = this.add.image(1350, 105, "defaultbutton");
      this.add.image(1350, 102, "cart").setScale(0.12);
      shopButton.setInteractive({ useHandCursor: true });
      shopButton.on("pointerdown", () => {
        window.location.href = "/vendor";
      });

      const marketplaceButton = this.add.image(1350, 165, "defaultbutton");
      this.add.image(1350, 162, "globe").setScale(0.12);
      marketplaceButton.setInteractive({ useHandCursor: true });
      marketplaceButton.on("pointerdown", () => {
        window.location.href = "/marketplace";
      });

      const notificationButton = this.add.image(45, 650, "balanceinfo");
      const char_i = this.add.image(45, 644, "info").setScale(0.12);
      notificationButton.setInteractive({ useHandCursor: true });

      if (pname !== "alchemist") {
      }

      notificationButton.on("pointerdown", () => {
        // Toggle the visibility of the panel
        if (this.panelContainer) {
          if (this.panelContainer.visible) {
            // If the panel is visible, hide it
            this.panelContainer.setVisible(false);
          } else {
            // If the panel is hidden, show it
            this.panelContainer.setVisible(true);
          }
        } else {
          // If the panel is not created yet, create it
          this.createScrollablePanel();
          // Make sure it's visible after creation
          this.panelContainer.setVisible(true);
        }
      });

      if (pname !== "village") {
        const backbutton = this.add.image(45, 45, "backbutton");
        const arrow = this.add.image(45, 42, "arrow");
        arrow.setScale(0.5);
        backbutton.setInteractive({ useHandCursor: true });
        backbutton.on("pointerdown", () => {
          window.location.href = "/village";
        });
      }
      this.time.addEvent({
        delay: 5000, // 3 seconds
        callback: this.totalItemsChecker,
        callbackScope: this,
        loop: true,
      });
      console.log(">>> ui is loaded.");
    } catch (error) {
      console.warn(error);
    }
  }

  update() {
    // Check for changes in the total counts and update text if needed
    if (this.totalMeatCount !== this.previousMeatCount) {
      if (this.totalMeatCount) {
        this.totalMeatText.setText(this.totalMeatCount);
      }
      this.previousMeatCount = this.totalMeatCount;
    }

    if (this.totalWoodCount !== this.previousWoodCount) {
      if (this.totalWoodText) {
        this.totalWoodText.setText(this.totalWoodCount);
      }
      this.previousWoodCount = this.totalWoodCount;
    }

    if (this.totalCoinCount !== this.previousCoinCount) {
      if (this.totalCoinText) {
        this.totalCoinText.setText(this.totalCoinCount);
      }
      this.previousCoinCount = this.totalCoinCount;
    }

    if (this.totalPotionCount !== this.previousPotionCount) {
      if (this.totalPotionText) {
        this.totalPotionText.setText(this.totalPotionCount);
      }
      this.previousPotionCount = this.totalPotionCount;
    }
  }

  openWindow = () => {
    if (!this.state.popup) {
      this.state.infoGroup = this.add.group();
      this.state.popup = this.add.sprite(490, 140, "modalbackground");
      this.state.popup.alpha = 1;
      this.state.popup.setScale(3, 2);
      this.closepanel = this.add.sprite(850, 50, "x").setScale(0.1, 0.1);
      this.closepanel.setInteractive({ useHandCursor: true });
      this.closepanel.on("pointerdown", () => {
        this.state.popup.setVisible(false);
        this.closepanel.setVisible(false);
        this.state.infoGroup.setVisible(false);
      });

      //#region INFO_TEXTS
      const walletText1 = this.add.text(150, 80, "Wallet Address:", {
        font: "18px Courier",
        fill: "#ffffff",
        backgroundColor: "#000",
      });
      this.state.infoGroup.add(walletText1);

      const walletText2 = this.add.text(
        320,
        80,
        JSON.parse(sessionStorage.getItem("user")).wallet,
        {
          font: "18px Courier",
          fill: "#ffffff",
          backgroundColor: "#000",
        }
      );
      this.state.infoGroup.add(walletText2);

      const balanceText1 = this.add.text(150, 105, "Balance:", {
        font: "18px Courier",
        fill: "#ffffff",
        backgroundColor: "#000",
      });
      this.state.infoGroup.add(balanceText1);

      const balanceText2 = this.add.text(
        320,
        105,
        "$ " + JSON.parse(sessionStorage.getItem("user")).balance,
        {
          font: "18px Courier",
          fill: "#ffffff",
          backgroundColor: "#000",
        }
      );
      this.state.infoGroup.add(balanceText2);

      const refCountText1 = this.add.text(150, 130, "Neighbor Count:", {
        font: "18px Courier",
        fill: "#ffffff",
        backgroundColor: "#000",
      });
      this.state.infoGroup.add(refCountText1);

      const refCountText2 = this.add.text(
        320,
        130,
        JSON.parse(sessionStorage.getItem("user")).ref_count,
        {
          font: "18px Courier",
          fill: "#ffffff",
          backgroundColor: "#000",
        }
      );
      this.state.infoGroup.add(refCountText2);

      const joinDateText1 = this.add.text(150, 155, "Join Date:", {
        font: "18px Courier",
        fill: "#ffffff",
        backgroundColor: "#000",
      });
      this.state.infoGroup.add(joinDateText1);

      const joinDateText2 = this.add.text(
        320,
        155,
        JSON.parse(sessionStorage.getItem("user")).join_date,
        {
          font: "18px Courier",
          fill: "#ffffff",
          backgroundColor: "#000",
        }
      );
      this.state.infoGroup.add(joinDateText2);

      this.state.infoGroup.children.iterate((child) => {
        // Example: Set the alpha of all items in the group
        child.setAlpha(1);
      });

      //#endregion
    } else {
      console.log(">>> popup exists.");
      this.state.popup.alpha = 1;
      this.state.popup.setScale(3, 2);
      this.state.popup.setVisible(true);
      this.closepanel.setVisible(true);
      this.state.infoGroup.setVisible(true);
    }
  };

  genRanHex = (size) =>
    [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");

  createScrollablePanel() {
    const panelWidth = 700; // The width of the panel background
    const panelHeight = 500; // The height of the panel background
    const panelX = 290; // X position of the panel
    const panelY = 100; // Y position of the panel
    const padding = 50; // Margin from all sides (adjusted to 50 pixels)

    // Create a container for the entire panel, including all elements
    this.panelContainer = this.add.container(panelX, panelY);

    // Add a modal background using a sprite instead of a graphics object
    const panelBackground = this.add
      .sprite(0, 0, "modalbackground")
      .setScale(3, 4);
    panelBackground.setOrigin(0, 0);

    // Add the background sprite to the panel container
    this.panelContainer.add(panelBackground);

    // Add a fixed title text to the panel (added to the panel container)
    const infoText1 = this.add.text(
      padding,
      padding,
      "🖍️ Check your collectible amounts! With your collectables, you can evolve some of your monsters. " +
      "Go to the Alchemist 💎 to explore and try evolving your monsters.",
      {
        font: "18px Chicago_12",
        fill: "#ffd733",
        wordWrap: { width: panelWidth - 2 * padding }, // Wrap the text to fit within the panel width with padding
      }
    );
    /*const infoText2 = this.add.text(padding, padding*2, "If you see the red '>>> Evolve 🧬' notification on your monster's pop-up you can proceed to the Alchemist's shop.", {
        font: '18px Chicago_12',
        fill: '#ffd733',
        wordWrap: { width: panelWidth - 2 * padding } // Wrap the text to fit within the panel width with padding
      });*/
    infoText1.setDepth(1);
    //infoText2.setDepth(1);

    // Add the title text to the panel container
    this.panelContainer.add(infoText1);
    //this.panelContainer.add(infoText2);

    // Add a close button to the panel
    const closeButton = this.add
      .sprite(panelX + panelWidth / 2 + 100, panelY - 40, "x")
      .setScale(0.1, 0.1);
    closeButton.setOrigin(1, 0);
    closeButton.setInteractive({ useHandCursor: true });
    closeButton.setDepth(1);

    // Add the close button to the panel container
    this.panelContainer.add(closeButton);

    // Add functionality to close the panel when the button is clicked
    closeButton.on("pointerdown", () => {
      // Set the entire panel container to be invisible
      this.panelContainer.setVisible(false);
    });

    // Initially set the panel container as invisible
    this.panelContainer.setVisible(false);
  }

  // Function to open the panel
  openScrollablePanel() {
    if (this.panelContainer) {
      this.panelContainer.setVisible(true);
    }
  }
}

export default UIScene;
